<template>
	<section>
		<div v-if="cargando" class="card">
			<div class="card-body">
				<div class="loading loading-lg"></div>
			</div>
		</div>
		<div v-else class="card">
			<div class="card-header">
				<div class="card-title h4">Datos del Cliente</div>
			</div>
			<div class="card-body columns">
				<div class="column col-md-12 col-4">
					<strong>Nombre completo</strong>
					<p>{{ cliente.nombre }}</p>
				</div>
				<div class="column col-md-12 col-4">
					<strong>Cedula</strong>
					<p>{{ cliente.cedula }}</p>
				</div>
				<div class="column col-md-12 col-4">
					<strong>Teléfono</strong>
					<p>{{ cliente.telefono }}</p>
				</div>
				<div class="column col-md-12 col-4">
					<strong>Dirección</strong>
					<p>{{ cliente.direccion }}</p>
				</div>
				<div class="column col-md-12 col-4">
					<strong>Creado el</strong>
					<p>{{ cliente.creado }}</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'TN_Cliente',
		data() {
			return {
				cargando: false,
				cliente: {}
			};
		},
		mounted() {
			document.title = 'Cargando...';
			this.infoUsuario();
			this.cargarCliente();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			cargarCliente: async function() {
				this.cargando = true;
				this.$store.state.api
					.get('clientes/ver/' + parseInt(this.$route.params.id))
					.then(res => {
						this.cliente = res.data;
						document.title = `Cliente: ${res.data.nombre}`;
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando = false);
			},
		},
	}
</script>